import React from 'react'

export const AppStore = () => {
    return (
        <svg width="171" height="57" viewBox="0 0 171 57" fill="none" role="presentation" alt="free workout plan app">
            <g clip-path="url(#clip0)">
                <path d="M156.942 6.31797e-05H13.5869C13.0644 6.31797e-05 12.5481 6.31798e-05 12.0269 0.00291318C11.5907 0.00576318 11.1579 0.0140424 10.7174 0.0210107C9.76056 0.0322707 8.80594 0.116452 7.86187 0.272822C6.91912 0.432597 6.00591 0.733818 5.15311 1.1663C4.30136 1.60244 3.52309 2.16915 2.84654 2.84589C2.16643 3.52072 1.59951 4.30074 1.16757 5.15594C0.734445 6.00944 0.434113 6.92405 0.276949 7.86817C0.118291 8.8111 0.0329152 9.7649 0.0215888 10.721C0.00836475 11.1579 0.00696825 11.5963 0 12.0332V44.9711C0.00696825 45.4135 0.00836475 45.8422 0.0215888 46.2848C0.0329187 47.2408 0.118294 48.1946 0.276949 49.1375C0.433679 50.0822 0.734029 50.9973 1.16757 51.8511C1.59931 52.7035 2.16631 53.4804 2.84654 54.1515C3.52053 54.8312 4.2993 55.3983 5.15311 55.8311C6.0059 56.2648 6.91902 56.5678 7.86187 56.73C8.8061 56.8851 9.76062 56.9694 10.7174 56.982C11.1579 56.9917 11.5907 56.9972 12.0269 56.9972C12.5481 57.0001 13.0644 57.0001 13.5869 57.0001H156.942C157.454 57.0001 157.975 57.0001 158.487 56.9972C158.921 56.9972 159.366 56.9917 159.8 56.982C160.755 56.97 161.708 56.8858 162.65 56.73C163.596 56.5666 164.513 56.2637 165.37 55.8311C166.223 55.3981 167.001 54.831 167.674 54.1515C168.353 53.4778 168.921 52.7015 169.358 51.8511C169.788 50.9967 170.086 50.0816 170.24 49.1375C170.399 48.1945 170.487 47.2409 170.505 46.2848C170.51 45.8422 170.51 45.4135 170.51 44.9711C170.521 44.4534 170.521 43.9385 170.521 43.4124V13.589C170.521 13.0672 170.521 12.5495 170.51 12.0332C170.51 11.5963 170.51 11.1579 170.505 10.721C170.487 9.76476 170.399 8.81117 170.24 7.86812C170.085 6.92454 169.788 6.01 169.358 5.15588C168.478 3.44172 167.083 2.0464 165.37 1.16617C164.513 0.734747 163.596 0.433606 162.65 0.272694C161.708 0.115635 160.756 0.0314242 159.8 0.0208112C159.366 0.0138572 158.921 0.00550668 158.487 0.00272793C157.975 -0.00012207 157.454 -0.00012207 156.942 -0.00012207V6.31797e-05Z" fill="#A6A6A6"/>
                <path d="M12.0339 55.7531C11.5997 55.7531 11.176 55.7476 10.7453 55.7379C9.85292 55.7263 8.96264 55.6486 8.08176 55.5055C7.26037 55.364 6.46467 55.1009 5.7209 54.7247C4.98395 54.3517 4.31179 53.8626 3.73018 53.2761C3.14014 52.6965 2.64891 52.0243 2.27595 51.286C1.89888 50.543 1.63792 49.7466 1.50217 48.9245C1.35558 48.0412 1.27626 47.148 1.26491 46.2527C1.25588 45.9521 1.24405 44.9515 1.24405 44.9515V12.0332C1.24405 12.0332 1.25665 11.0479 1.26498 10.7585C1.27585 9.86455 1.3547 8.97274 1.50086 8.09078C1.63686 7.26644 1.89802 6.46773 2.27529 5.72228C2.64688 4.98458 3.13539 4.31186 3.72187 3.73021C4.30769 3.14302 4.982 2.65137 5.7202 2.27319C6.46226 1.89825 7.25646 1.63696 8.07618 1.49807C8.95996 1.35353 9.85333 1.27538 10.7488 1.26429L12.0346 1.24689H158.48L159.781 1.26498C160.668 1.27553 161.554 1.35298 162.429 1.49668C163.257 1.6373 164.06 1.90041 164.81 2.27736C166.289 3.03952 167.492 4.24532 168.252 5.72576C168.623 6.46606 168.88 7.25826 169.014 8.07547C169.162 8.96467 169.245 9.86349 169.262 10.7647C169.266 11.1683 169.266 11.6018 169.266 12.0332C169.277 12.5676 169.277 13.0762 169.277 13.589V43.4124C169.277 43.9301 169.277 44.4352 169.266 44.9445C169.266 45.4079 169.266 45.8324 169.26 46.2693C169.244 47.1545 169.162 48.0373 169.017 48.9106C168.884 49.7386 168.624 50.5412 168.247 51.2903C167.872 52.0205 167.383 52.6869 166.8 53.2649C166.218 53.8546 165.545 54.3466 164.806 54.722C164.058 55.1011 163.256 55.3652 162.429 55.5055C161.548 55.6493 160.658 55.727 159.766 55.7379C159.348 55.7476 158.911 55.7531 158.487 55.7531L156.942 55.756L12.0339 55.7531Z" fill="black"/>
                <path d="M35.2957 28.9285C35.311 27.7391 35.6269 26.5729 36.2141 25.5384C36.8012 24.5039 37.6405 23.6348 38.6538 23.0118C38.01 22.0924 37.1608 21.3358 36.1735 20.8021C35.1863 20.2684 34.0881 19.9722 32.9664 19.9371C30.5735 19.6859 28.2536 21.369 27.0342 21.369C25.7913 21.369 23.9138 19.962 21.8921 20.0036C20.5844 20.0459 19.31 20.4261 18.193 21.1074C17.0759 21.7886 16.1544 22.7476 15.5182 23.8908C12.7623 28.6623 14.818 35.6748 17.4579 39.5317C18.7788 41.4204 20.3224 43.53 22.3423 43.4552C24.3189 43.3732 25.0571 42.1948 27.4429 42.1948C29.8067 42.1948 30.4993 43.4552 32.5601 43.4076C34.6811 43.3732 36.0174 41.5106 37.2918 39.6041C38.2409 38.2584 38.9711 36.7711 39.4556 35.1973C38.2234 34.6761 37.1718 33.8038 36.4321 32.689C35.6923 31.5742 35.2971 30.2664 35.2957 28.9285Z" fill="white"/>
                <path d="M31.4031 17.4005C32.5595 16.0123 33.1292 14.2279 32.9913 12.4264C31.2245 12.612 29.5925 13.4564 28.4205 14.7913C27.8474 15.4435 27.4085 16.2022 27.1289 17.0241C26.8492 17.846 26.7343 18.715 26.7907 19.5813C27.6744 19.5904 28.5486 19.3989 29.3476 19.0211C30.1465 18.6434 30.8493 18.0893 31.4031 17.4005Z" fill="white"/>
                <path d="M60.2807 38.6732H53.5357L51.9158 43.4561H49.0589L55.4477 25.7605H58.416L64.8048 43.4561H61.8992L60.2807 38.6732ZM54.2342 36.4661H59.5808L56.9451 28.7038H56.8714L54.2342 36.4661Z" fill="white"/>
                <path d="M78.6026 37.0069C78.6026 41.0161 76.4567 43.592 73.2185 43.592C72.3982 43.6349 71.5823 43.4459 70.8644 43.0467C70.1465 42.6475 69.5556 42.0542 69.1592 41.3348H69.0979V47.725H66.4497V30.5554H69.013V32.7013H69.0618C69.4764 31.9853 70.0773 31.395 70.8007 30.9934C71.5241 30.5918 72.3428 30.3938 73.1698 30.4204C76.4441 30.4205 78.6026 33.0088 78.6026 37.0069ZM75.8806 37.0069C75.8806 34.3949 74.5307 32.6776 72.4712 32.6776C70.4478 32.6776 69.0868 34.431 69.0868 37.0069C69.0868 39.6064 70.4478 41.3473 72.4712 41.3473C74.5307 41.3473 75.8806 39.6426 75.8806 37.0069Z" fill="white"/>
                <path d="M92.8025 37.0069C92.8025 41.0161 90.6566 43.5919 87.4184 43.5919C86.5981 43.6349 85.7823 43.4459 85.0644 43.0467C84.3465 42.6475 83.7555 42.0542 83.3591 41.3347H83.2978V47.725H80.6496V30.5554H83.2129V32.7013H83.2616C83.6762 31.9853 84.2772 31.395 85.0006 30.9934C85.724 30.5918 86.5427 30.3938 87.3696 30.4204C90.6441 30.4204 92.8025 33.0088 92.8025 37.0069ZM90.0805 37.0069C90.0805 34.3949 88.7307 32.6776 86.6711 32.6776C84.6477 32.6776 83.2867 34.431 83.2867 37.0069C83.2867 39.6064 84.6477 41.3473 86.6711 41.3473C88.7307 41.3473 90.0805 39.6426 90.0805 37.0069H90.0805Z" fill="white"/>
                <path d="M102.187 38.5265C102.384 40.2813 104.088 41.4335 106.418 41.4335C108.65 41.4335 110.256 40.2813 110.256 38.699C110.256 37.3255 109.287 36.5031 106.994 35.9395L104.701 35.387C101.451 34.6021 99.9428 33.0825 99.9428 30.6166C99.9428 27.5634 102.604 25.4662 106.382 25.4662C110.121 25.4662 112.684 27.5634 112.771 30.6166H110.097C109.937 28.8506 108.477 27.7847 106.344 27.7847C104.211 27.7847 102.751 28.8632 102.751 30.4329C102.751 31.6839 103.683 32.4201 105.964 32.9836L107.914 33.4623C111.545 34.321 113.053 35.7794 113.053 38.3677C113.053 41.6784 110.416 43.7518 106.222 43.7518C102.297 43.7518 99.6477 41.7271 99.4766 38.5264L102.187 38.5265Z" fill="white"/>
                <path d="M118.768 27.5022V30.5554H121.222V32.6525H118.768V39.765C118.768 40.8699 119.26 41.3848 120.338 41.3848C120.629 41.3798 120.92 41.3593 121.209 41.3236V43.4082C120.724 43.4988 120.232 43.5398 119.738 43.5307C117.126 43.5307 116.108 42.5496 116.108 40.0475V32.6525H114.232V30.5554H116.108V27.5022H118.768Z" fill="white"/>
                <path d="M122.643 37.0069C122.643 32.9476 125.033 30.3968 128.762 30.3968C132.502 30.3968 134.882 32.9475 134.882 37.0069C134.882 41.0773 132.515 43.617 128.762 43.617C125.01 43.617 122.643 41.0773 122.643 37.0069ZM132.183 37.0069C132.183 34.2223 130.907 32.5788 128.762 32.5788C126.616 32.5788 125.341 34.2348 125.341 37.0069C125.341 39.8026 126.616 41.4335 128.762 41.4335C130.907 41.4335 132.183 39.8026 132.183 37.0069H132.183Z" fill="white"/>
                <path d="M137.065 30.5554H139.591V32.7513H139.652C139.823 32.0655 140.225 31.4594 140.79 31.0349C141.355 30.6104 142.049 30.3934 142.755 30.4204C143.061 30.4193 143.365 30.4525 143.663 30.5192V32.9963C143.277 32.8785 142.876 32.8245 142.473 32.8362C142.088 32.8206 141.705 32.8885 141.349 33.0351C140.993 33.1817 140.672 33.4036 140.41 33.6856C140.148 33.9676 139.95 34.303 139.83 34.6687C139.71 35.0345 139.67 35.4219 139.713 35.8045V43.4569H137.065L137.065 30.5554Z" fill="white"/>
                <path d="M155.873 39.6676C155.516 42.0097 153.236 43.617 150.317 43.617C146.564 43.617 144.235 41.1023 144.235 37.0681C144.235 33.0213 146.577 30.3968 150.206 30.3968C153.775 30.3968 156.02 32.8488 156.02 36.7605V37.6679H146.908V37.8279C146.866 38.3027 146.925 38.781 147.082 39.2311C147.239 39.6812 147.49 40.0927 147.819 40.4383C148.147 40.784 148.545 41.0558 148.986 41.2358C149.428 41.4158 149.902 41.4998 150.379 41.4822C151.004 41.5409 151.632 41.3959 152.169 41.069C152.706 40.742 153.123 40.2505 153.358 39.6676L155.873 39.6676ZM146.92 35.817H153.371C153.394 35.3901 153.33 34.9629 153.18 34.5622C153.031 34.1614 152.801 33.7959 152.504 33.4884C152.207 33.1809 151.849 32.9382 151.454 32.7755C151.058 32.6127 150.634 32.5334 150.206 32.5426C149.775 32.54 149.347 32.6229 148.948 32.7864C148.549 32.9499 148.186 33.1908 147.881 33.4953C147.576 33.7997 147.333 34.1616 147.169 34.5601C147.004 34.9586 146.919 35.3858 146.92 35.8171V35.817Z" fill="white"/>
                <path d="M53.9023 12.4405C54.4575 12.4007 55.0146 12.4846 55.5335 12.6861C56.0523 12.8876 56.52 13.2018 56.9027 13.606C57.2855 14.0102 57.5737 14.4942 57.7467 15.0233C57.9197 15.5524 57.9732 16.1132 57.9032 16.6654C57.9032 19.3818 56.435 20.9433 53.9023 20.9433H50.8311V12.4405H53.9023ZM52.1517 19.7408H53.7548C54.1515 19.7645 54.5485 19.6995 54.917 19.5504C55.2854 19.4013 55.6159 19.172 55.8845 18.879C56.1531 18.5861 56.353 18.237 56.4697 17.857C56.5863 17.4771 56.6168 17.0759 56.5589 16.6828C56.6126 16.2911 56.579 15.8924 56.4606 15.5153C56.3422 15.1381 56.1418 14.7919 55.8738 14.5013C55.6058 14.2107 55.2768 13.9829 54.9105 13.8344C54.5441 13.6859 54.1495 13.6202 53.7548 13.6421H52.1517V19.7408Z" fill="white"/>
                <path d="M59.3949 17.7332C59.3546 17.3115 59.4028 16.8861 59.5366 16.4841C59.6703 16.0822 59.8865 15.7126 60.1714 15.3991C60.4564 15.0857 60.8037 14.8352 61.191 14.6638C61.5784 14.4924 61.9974 14.4039 62.421 14.4039C62.8446 14.4039 63.2635 14.4924 63.6509 14.6638C64.0383 14.8352 64.3856 15.0857 64.6705 15.3991C64.9554 15.7126 65.1717 16.0822 65.3054 16.4841C65.4391 16.8861 65.4874 17.3115 65.447 17.7332C65.4881 18.1553 65.4405 18.5814 65.3071 18.984C65.1737 19.3866 64.9576 19.7568 64.6726 20.0709C64.3876 20.385 64.0401 20.636 63.6523 20.8077C63.2645 20.9795 62.8451 21.0682 62.421 21.0682C61.9969 21.0682 61.5774 20.9795 61.1897 20.8077C60.8019 20.636 60.4543 20.385 60.1693 20.0709C59.8844 19.7568 59.6682 19.3866 59.5349 18.984C59.4015 18.5814 59.3538 18.1553 59.3949 17.7332ZM64.1445 17.7332C64.1445 16.3423 63.5196 15.5289 62.4231 15.5289C61.3223 15.5289 60.7031 16.3423 60.7031 17.7332C60.7031 19.1353 61.3223 19.9424 62.4231 19.9424C63.5197 19.9424 64.1445 19.1297 64.1445 17.7332H64.1445Z" fill="white"/>
                <path d="M73.4919 20.9443H72.1782L70.852 16.2184H70.7518L69.4312 20.9443H68.1301L66.3613 14.5276H67.6458L68.7952 19.4239H68.8899L70.2091 14.5276H71.424L72.7432 19.4239H72.8434L73.9873 14.5276H75.2537L73.4919 20.9443Z" fill="white"/>
                <path d="M76.7413 14.5276H77.9603V15.547H78.0549C78.2155 15.1809 78.4862 14.874 78.8295 14.669C79.1727 14.4641 79.5713 14.3714 79.9698 14.4038C80.282 14.3803 80.5955 14.4274 80.8871 14.5415C81.1786 14.6557 81.4407 14.8339 81.654 15.0631C81.8673 15.2923 82.0263 15.5666 82.1192 15.8656C82.2122 16.1646 82.2366 16.4806 82.1908 16.7904V20.9442H80.9244V17.1084C80.9244 16.0772 80.4763 15.5644 79.5398 15.5644C79.3278 15.5545 79.1162 15.5906 78.9195 15.6701C78.7227 15.7497 78.5455 15.8709 78.4 16.0253C78.2545 16.1798 78.1441 16.3638 78.0763 16.565C78.0086 16.7661 77.9852 16.9794 78.0076 17.1905V20.9443H76.7413L76.7413 14.5276Z" fill="white"/>
                <path d="M84.2086 12.0227H85.475V20.9443H84.2086V12.0227Z" fill="white"/>
                <path d="M87.2353 17.7332C87.195 17.3115 87.2433 16.886 87.3771 16.484C87.5108 16.0821 87.7271 15.7125 88.0121 15.399C88.297 15.0855 88.6443 14.8351 89.0317 14.6637C89.4191 14.4923 89.8381 14.4037 90.2617 14.4037C90.6854 14.4037 91.1043 14.4923 91.4917 14.6637C91.8792 14.8351 92.2265 15.0855 92.5114 15.399C92.7964 15.7125 93.0126 16.0821 93.1464 16.484C93.2802 16.886 93.3284 17.3115 93.2881 17.7332C93.3292 18.1553 93.2815 18.5814 93.148 18.984C93.0146 19.3866 92.7985 19.7568 92.5135 20.0709C92.2284 20.385 91.8809 20.636 91.4931 20.8077C91.1053 20.9795 90.6859 21.0682 90.2617 21.0682C89.8376 21.0682 89.4182 20.9795 89.0304 20.8077C88.6426 20.636 88.295 20.385 88.01 20.0709C87.725 19.7568 87.5088 19.3866 87.3754 18.984C87.242 18.5814 87.1943 18.1553 87.2353 17.7332ZM91.9849 17.7332C91.9849 16.3423 91.36 15.5289 90.2635 15.5289C89.1627 15.5289 88.5435 16.3423 88.5435 17.7332C88.5435 19.1353 89.1628 19.9424 90.2635 19.9424C91.36 19.9424 91.9849 19.1297 91.9849 17.7332H91.9849Z" fill="white"/>
                <path d="M94.6213 19.1296C94.6213 17.9746 95.4813 17.3087 97.0079 17.2141L98.746 17.1139V16.5601C98.746 15.8823 98.2979 15.4997 97.4323 15.4997C96.7254 15.4997 96.2355 15.7592 96.095 16.2129H94.869C94.9984 15.1107 96.0352 14.4038 97.4908 14.4038C99.0995 14.4038 100.007 15.2046 100.007 16.5601V20.9443H98.7877V20.0425H98.6875C98.4842 20.366 98.1986 20.6297 97.86 20.8067C97.5214 20.9837 97.1418 21.0677 96.7602 21.05C96.4908 21.078 96.2185 21.0493 95.961 20.9657C95.7034 20.882 95.4662 20.7453 95.2647 20.5644C95.0631 20.3835 94.9018 20.1623 94.791 19.9152C94.6801 19.6681 94.6223 19.4005 94.6213 19.1296ZM98.746 18.5813V18.0449L97.179 18.1451C96.2954 18.2042 95.8946 18.5048 95.8946 19.0705C95.8946 19.648 96.3956 19.9841 97.0845 19.9841C97.2863 20.0045 97.4902 19.9841 97.684 19.9241C97.8778 19.8642 98.0576 19.7658 98.2126 19.6349C98.3676 19.5041 98.4947 19.3434 98.5864 19.1623C98.678 18.9813 98.7323 18.7838 98.746 18.5813Z" fill="white"/>
                <path d="M101.671 17.7332C101.671 15.7056 102.713 14.4212 104.335 14.4212C104.736 14.4027 105.134 14.4988 105.482 14.6981C105.831 14.8975 106.115 15.1919 106.302 15.5469H106.397V12.0227H107.663V20.9443H106.45V19.9305H106.35C106.148 20.2832 105.854 20.5739 105.498 20.7713C105.143 20.9686 104.741 21.065 104.335 21.05C102.702 21.0501 101.671 19.7656 101.671 17.7332ZM102.979 17.7332C102.979 19.0942 103.621 19.9131 104.694 19.9131C105.761 19.9131 106.421 19.0824 106.421 17.7388C106.421 16.4014 105.754 15.5588 104.694 15.5588C103.628 15.5588 102.979 16.3833 102.979 17.7332H102.979Z" fill="white"/>
                <path d="M112.903 17.7332C112.862 17.3115 112.911 16.8861 113.044 16.4841C113.178 16.0822 113.394 15.7126 113.679 15.3991C113.964 15.0857 114.311 14.8352 114.699 14.6638C115.086 14.4924 115.505 14.4039 115.929 14.4039C116.352 14.4039 116.771 14.4924 117.159 14.6638C117.546 14.8352 117.893 15.0857 118.178 15.3991C118.463 15.7126 118.679 16.0822 118.813 16.4841C118.947 16.8861 118.995 17.3115 118.955 17.7332C118.996 18.1553 118.948 18.5814 118.815 18.984C118.682 19.3866 118.465 19.7568 118.18 20.0709C117.895 20.385 117.548 20.636 117.16 20.8077C116.772 20.9795 116.353 21.0682 115.929 21.0682C115.505 21.0682 115.085 20.9795 114.697 20.8077C114.31 20.636 113.962 20.385 113.677 20.0709C113.392 19.7568 113.176 19.3866 113.043 18.984C112.909 18.5814 112.862 18.1553 112.903 17.7332ZM117.652 17.7332C117.652 16.3423 117.027 15.5289 115.931 15.5289C114.83 15.5289 114.211 16.3423 114.211 17.7332C114.211 19.1353 114.83 19.9424 115.931 19.9424C117.027 19.9424 117.652 19.1297 117.652 17.7332Z" fill="white"/>
                <path d="M120.654 14.5276H121.873V15.547H121.968C122.128 15.1809 122.399 14.874 122.742 14.669C123.085 14.4641 123.484 14.3714 123.882 14.4038C124.195 14.3803 124.508 14.4274 124.8 14.5415C125.091 14.6557 125.353 14.8339 125.567 15.0631C125.78 15.2923 125.939 15.5666 126.032 15.8656C126.125 16.1646 126.149 16.4806 126.103 16.7904V20.9442H124.837V17.1084C124.837 16.0772 124.389 15.5644 123.452 15.5644C123.24 15.5545 123.029 15.5906 122.832 15.6701C122.635 15.7497 122.458 15.8709 122.313 16.0253C122.167 16.1798 122.057 16.3638 121.989 16.565C121.921 16.7661 121.898 16.9794 121.92 17.1905V20.9443H120.654V14.5276Z" fill="white"/>
                <path d="M133.259 12.9301V14.5569H134.649V15.6235H133.259V18.923C133.259 19.5952 133.536 19.8895 134.166 19.8895C134.328 19.889 134.489 19.8792 134.649 19.8603V20.9151C134.422 20.9558 134.191 20.9774 133.96 20.9798C132.552 20.9798 131.991 20.4844 131.991 19.2473V15.6235H130.973V14.5568H131.991V12.9301H133.259Z" fill="white"/>
                <path d="M136.379 12.0227H137.634V15.5588H137.734C137.903 15.1893 138.181 14.8806 138.531 14.6749C138.881 14.4692 139.286 14.3765 139.691 14.4093C140.002 14.3924 140.312 14.4443 140.6 14.5612C140.889 14.6781 141.147 14.8572 141.359 15.0857C141.57 15.3142 141.728 15.5864 141.821 15.883C141.915 16.1796 141.942 16.4932 141.901 16.8015V20.9443H140.633V17.1139C140.633 16.089 140.156 15.5699 139.261 15.5699C139.043 15.5521 138.825 15.582 138.62 15.6576C138.415 15.7332 138.229 15.8526 138.075 16.0076C137.921 16.1625 137.803 16.3493 137.729 16.5546C137.655 16.76 137.626 16.9792 137.645 17.1967V20.9443H136.379L136.379 12.0227Z" fill="white"/>
                <path d="M149.285 19.2118C149.113 19.7982 148.74 20.3055 148.232 20.6449C147.723 20.9842 147.112 21.1341 146.504 21.0682C146.082 21.0793 145.661 20.9984 145.273 20.8309C144.885 20.6634 144.538 20.4134 144.256 20.0983C143.974 19.7832 143.764 19.4105 143.64 19.006C143.516 18.6016 143.482 18.1751 143.54 17.7562C143.484 17.336 143.519 16.9085 143.642 16.5029C143.765 16.0972 143.974 15.7228 144.255 15.4049C144.535 15.087 144.881 14.8331 145.268 14.6604C145.655 14.4877 146.075 14.4002 146.499 14.4038C148.284 14.4038 149.361 15.6236 149.361 17.6385V18.0804H144.83V18.1514C144.81 18.3869 144.84 18.6239 144.917 18.8473C144.994 19.0706 145.117 19.2754 145.278 19.4485C145.439 19.6216 145.634 19.7591 145.851 19.8523C146.069 19.9455 146.303 19.9923 146.539 19.9896C146.842 20.026 147.149 19.9715 147.421 19.8329C147.693 19.6944 147.917 19.4782 148.066 19.2117L149.285 19.2118ZM144.83 17.1439H148.071C148.087 16.9285 148.058 16.7122 147.985 16.5088C147.913 16.3054 147.798 16.1195 147.65 15.963C147.501 15.8064 147.321 15.6827 147.122 15.5998C146.922 15.5169 146.708 15.4766 146.492 15.4816C146.273 15.4788 146.055 15.52 145.853 15.6025C145.65 15.6851 145.465 15.8075 145.311 15.9624C145.156 16.1173 145.033 16.3016 144.951 16.5045C144.868 16.7075 144.827 16.9248 144.83 17.1439H144.83Z" fill="white"/>
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width="170.521" height="57" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    )
}
