import { rem } from 'polished'

const breakpoints = ['320px', '640px', '960px', '1008px', '1280px']

breakpoints.sm = breakpoints[0]
breakpoints.md = breakpoints[1]
breakpoints.lg = breakpoints[2]
breakpoints.xl = breakpoints[3]
breakpoints.xxl = breakpoints[4]

const primitives = {
  breakpoints,
  space: [0, 4, 8, 12, 16, 24, 32, 48, 64],
  fontSizes: [14, 16, 20, 24, 36, 48],
  fontFamily:
    '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
  lineHeights: [24, 28, 32, 40, 48],
  fontWeights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
  colors: {
    purple: 'rgba(76, 53, 202)',
    complimentry: '#ca6735',
    black: 'rgb(0, 0, 0)',
    white: 'rgb(255, 255, 255)',
    transparent: 'transparent',
    grey: 'rgb(200, 200, 200)',
    lightGrey: 'rgb(240, 240, 240)',
    red: 'rgb(202, 0, 0)',
  },
}

export const theme = {
  ...primitives,
  textStyle: {
    caption: {
      fontSize: rem(primitives.fontSizes[0]),
      lineHeight: rem('24px'),
    },
    body: {
      fontSize: rem(primitives.fontSizes[1]),
      lineHeight: rem('24px'),
    },
    displayOne: {
      fontSize: rem(primitives.fontSizes[5]),
      lineHeight: rem('48px'),
    },
    displayTwo: {
      fontSize: rem(primitives.fontSizes[4]),
      lineHeight: rem('36px'),
    },
    displayThree: {
      fontSize: rem(primitives.fontSizes[3]),
      lineHeight: rem('28px'),
    },
  },
  button: {
    primary: {
      borderRadius: primitives.space[4],
      color: primitives.colors.white,
      backgroundColor: primitives.colors.purple,
      fontSize: rem(primitives.fontSizes[1]),
      lineHeight: rem(primitives.lineHeights[1]),
      textDecoration: 'none',
    },
    complimentry: {
      borderRadius: primitives.space[4],
      color: primitives.colors.white,
      backgroundColor: primitives.colors.complimentry,
      fontSize: rem(primitives.fontSizes[1]),
      lineHeight: rem(primitives.lineHeights[1]),
      textDecoration: 'none',
    },
    secondary: {
      borderRadius: primitives.space[4],
      border: `1px solid ${primitives.colors.white}`,
      color: primitives.colors.white,
      backgroundColor: 'transparent',
      fontSize: rem(primitives.fontSizes[1]),
      lineHeight: rem(primitives.lineHeights[1]),
      textDecoration: 'none',
    },
    secondaryWhite: {
      borderRadius: primitives.space[4],
      border: `1px solid ${primitives.colors.white}`,
      color: primitives.colors.purple,
      backgroundColor: primitives.colors.white,
      fontSize: rem(primitives.fontSizes[1]),
      lineHeight: rem(primitives.lineHeights[1]),
      textDecoration: 'none',
      fontWeight: 'bold',
    },
  },
  badge: {
    primary: {
      fontSize: rem(primitives.fontSizes[0]),
      lineHeight: rem(primitives.fontSizes[1]),
      color: primitives.colors.white,
      backgroundColor: primitives.colors.black,
    },
    secondary: {
      fontSize: rem(primitives.fontSizes[0]),
      lineHeight: rem(primitives.fontSizes[1]),
      border: `1px solid ${primitives.colors.black}`,
      color: primitives.colors.black,
    },
  },
}
