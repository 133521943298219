import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { AppStore } from '../assets/AppStore'

export const AppStoreLink = () => {
    return (
        <StaticQuery
            query={graphql`
                query HeadingQuery {
                site {
                    siteMetadata {
                        appUrl
                    }
                }
                }
            `}
            render={data => (
                <a href={data.site.siteMetadata.appUrl} aria-label="Download free workout plan app"><AppStore /></a>
            )}
        />
    )
}
