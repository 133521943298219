import React from "react";
import { AppStoreLink } from "./AppStoreLink";
import {
  ThemeProvider,
  GridWrap,
  Grid,
  GridItem,
  Box,
  HorizontalList,
  Typography,
} from "@mintuz/marvel";
import css from "@styled-system/css";
import { theme } from "../theme";

import styled, { createGlobalStyle } from "styled-components";
import { Link } from "gatsby";

const GlobalStyle = createGlobalStyle`
    body {
        font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    }

    a {
        text-decoration: none;

        ${css({
          color: "purple",
        })}

        &:hover {
            text-decoration: underline;
        }

        &:visited {
            ${css({
              color: "purple",
            })}
        }
    }
`;
const FooterStyled = styled.div`
  ${css({
    backgroundColor: "lightGrey",
    padding: [6],
  })}

  a {
    font-weight: bold;
  }
`;

const SegmentThree = styled.div`
  padding-top: 100px;
  padding-bottom: 100px;

  ${css({
    backgroundColor: "purple",
    color: "white",
  })}
`;

export const Layout = ({ children }) => {
  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        {children}
        <SegmentThree>
          <GridWrap>
            <Grid>
              <GridItem width={[1 / 1]}>
                <Box display="flex" alignItems="center" flexDirection="column">
                  <Typography as="h2" variant="displayTwo" mb={[5]}>
                    Download Now
                  </Typography>
                  <AppStoreLink />
                </Box>
              </GridItem>
            </Grid>
          </GridWrap>
        </SegmentThree>
        <FooterStyled>
          <GridWrap>
            <Grid>
              <GridItem width={[1 / 1]}>
                <Box textAlign="center">
                  <HorizontalList>
                    <Box as="li" mr={[4]}>
                      <Link to="/faqs">FAQs</Link>
                    </Box>
                    <Box as="li" mr={[4]}>
                      <Link to="https://discord.gg/CVUnhXnBTT">Discord</Link>
                    </Box>
                    <Box as="li" mr={[4]}>
                      <Link to="https://twitter.com/GetWorkoutApp">
                        Twitter
                      </Link>
                    </Box>
                    <Box as="li" mr={[4]}>
                      <Link to="/privacy">Privacy Policy</Link>
                    </Box>
                    <Box as="li" mr={[4]}>
                      <Link to="/terms">Terms of Service</Link>
                    </Box>
                  </HorizontalList>
                </Box>
                <Box textAlign="center" mt={[4]}>
                  <p>Copyright &copy; 2020-2021 All rights reserved</p>
                  <p>
                    Built by <a href="https://mintuz.com">Mintuz</a>
                  </p>
                </Box>
              </GridItem>
            </Grid>
          </GridWrap>
        </FooterStyled>
      </ThemeProvider>
    </React.Fragment>
  );
};
